#benefits_container {
    @include set_size;
    @include grid_container($grid_columns: 1fr 1fr, $grid_rows: 1fr 1fr);

    font-size: var(--text_content_font);
    gap: $mid_v_margin $min_w_margin;

    @media screen and (max-width: $tablets_breakpoint) {
        @include grid_container($grid_columns: 100%, $grid_rows: repeat(4, 1fr));
    }
    
    .benefit {
        @include flex_container(column, $align: center);

        gap: $extra_min_v_margin;
    }
}