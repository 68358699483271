@mixin make_circle($width, $object_position: center) {
    width: $width;
    aspect-ratio: 1/1;
    border-radius: 50%;
    object-fit: cover;
    object-position: $object_position;
}
    
@mixin set_min_size($min_width: 100%, $min_height: 100%) {
    min-width: $min_width;
    min-height: $min_height;
}

@mixin set_size($width: 100%, $height: 100%) {
    width: $width;
    height: $height;
}

@mixin postioning($position: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0) {
    position: $position;
    inset: $top $right $bottom $left;
}

@mixin part_positioning($property, $position: absolute) {
    position: $position;
    #{$property}: 0
}

@mixin flex_container($direction: row, $justify: flex-start, $align: flex-start, $wrap: wrap) {
    display: flex;
    flex-flow: $direction $wrap;
    justify-content: $justify;
    align-items: $align;
}

@mixin grid_container($grid_columns: 100%, $grid_rows: 100%) {
    display: grid;
    grid-template-columns: $grid_columns;
    grid-template-rows: $grid_rows;
}